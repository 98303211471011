import React from "react"

import { Registration, Layout, SEO, Container } from "src/sws-ui"

const RegisterPage = () => (
  <Layout>
    <SEO title="Register" />
    <div className={"container"}>
      <Registration />
    </div>
  </Layout>
)

export default RegisterPage
